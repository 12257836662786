import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import SEO from "../components/seo/"

import "../styles/globals/index.scss"

import Split from "../components/split/"
import LogoBlock from "../components/logo-block/"

import { psColour, wlColour } from "../data/data.colours"


const IndexPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			psLogo: file(relativePath: { eq: "logos/ps-logo.svg" }) {
				publicURL
			}
			psBg: file(relativePath: { eq: "backgrounds/google-map-aerial-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 3000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			wlLogo: file(relativePath: { eq: "logos/wl-logo.svg" }) {
				publicURL
			}
			wlBg: file(relativePath: { eq: "backgrounds/aston41-exterior-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)

	return (
		<>
			<SEO title="Home"
				path={ location.pathname }/>
			<div id={`root`}>
				<Split mobFull={true}>
					<LogoBlock 
						colour={psColour.slug}
						hex={psColour.hex}
						brand={`Painesend`}
						logo={ data.psLogo.publicURL }
						background={ data.psBg.childImageSharp.fluid }
						link={`/painesend`}/>	
					<LogoBlock 
						colour={wlColour.slug}
						hex={wlColour.hex}
						brand={`W Lamb`}
						logo={ data.wlLogo.publicURL }
						background={ data.wlBg.childImageSharp.fluid }
						link={ `${process.env.GATSBY_SISTER_SITE}/w-lamb` }
						offsite/>
				</Split>
			</div>
		</>
	)
}

export default IndexPage